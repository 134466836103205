<template>
  <div>
    <Modal
      :offset-modal="true"
      :wrong-country-modal="true"
      class="!z-modal"
      @close-modal="closeModal()"
    >
      <div v-if="selectedMarket" class="p-24">
        <div>
          <div v-if="suggestedMarketName" class="mb-12 base-medium-text">
            {{ $lang('wrongCountryResources', 'suggestedCountryHeading') }}
          </div>
          <div v-else class="mb-12 base-medium-text">
            {{ $lang('wrongCountryResources', 'modalHeading') }}
            {{ currentMarketName }}
          </div>
          <div v-if="suggestedMarketName" class="mb-16 base-text">
            {{ $lang('wrongCountryResources', 'youAppearToBeIn') }}
            {{ suggestedMarketName }},
            {{ $lang('wrongCountryResources', 'suggestedCountryCta') }}
            {{ suggestedMarketName }}?
          </div>
          <div v-else class="mb-16 base-text">
            {{ $lang('wrongCountryResources', 'modalDescription') }}
          </div>
        </div>
        <div class="mb-24 relative">
          <div
            class="px-16 py-20 border border-grey600 rounded flex justify-between items-center bg-grey200 select-none cursor-pointer"
            :class="{
              'rounded-b-none': marketSelectorOpen,
            }"
            @click="openMarketSelector"
          >
            <div class="flex items-center base-text">
              <img v-if="selectedMarket.countryCode === 'SE'" src="~/assets/icons/flag-defs-se.svg" class="h-20 w-20 mr-8 object-contain" />
              <img v-else-if="selectedMarket.countryCode === 'DK'" src="~/assets/icons/flag-defs-dk.svg" class="h-20 w-20 mr-8 object-contain" />
              <img v-else-if="selectedMarket.countryCode === 'AT'" src="~/assets/icons/flag-defs-at.svg" class="h-20 w-20 mr-8 object-contain" />
              <img v-else-if="selectedMarket.countryCode === 'SK'" src="~/assets/icons/flag-defs-sk.svg" class="h-20 w-20 mr-8 object-contain" />
              <img v-else src="~/assets/icons/flag-defs-global.svg" class="h-20 w-20 mr-8 object-contain" />
              {{ selectedMarket.country }}
            </div>
            <fa :icon="['far', 'chevron-down']"></fa>
          </div>
          <transition name="market-appear" mode="out-in">
            <div
              v-if="marketSelectorOpen"
              class="absolute right-0 left-0 md:max-h-160 max-h-460 bg-white z-10 rounded-b border-b border-r border-l border-grey600 overflow-auto cursor-pointer select-none"
            >
              <div
                v-for="market in markets"
                :key="market.countryId"
                class="flex items-center p-16 cursor-pointer select-none"
                @click="onMarketSelect(market)"
              >
                <img v-if="market.countryCode === 'SE'" src="~/assets/icons/flag-defs-se.svg" class="h-20 w-20 mr-8 object-contain" />
                <img v-else-if="market.countryCode === 'DK'" src="~/assets/icons/flag-defs-dk.svg" class="h-20 w-20 mr-8 object-contain" />
                <img v-else-if="market.countryCode === 'AT'" src="~/assets/icons/flag-defs-at.svg" class="h-20 w-20 mr-8 object-contain" />
                <img v-else-if="market.countryCode === 'SK'" src="~/assets/icons/flag-defs-sk.svg" class="h-20 w-20 mr-8 object-contain" />
                <img v-else src="~/assets/icons/flag-defs-global.svg" class="h-20 w-20 mr-8 object-contain" />
                {{ market.country }}
              </div>
            </div>
          </transition>
        </div>
        <Button
          :size="Sizes.lg"
          :theme="Themes.dark"
          :text="selectedMarket.changeCountryLabel"
          :is-block="true"
          @click="onMarketApply"
        />
      </div>
    </Modal>
  </div>
</template>
<script setup lang="ts">
import { Sizes, Themes } from '../constants/button-layouts';
import Button from './globals/Button.vue';
import Modal from '~/components/modals/Modal.vue';
import { type IMarket } from '~/models/api-types';
import { useGlobalContentStore } from '~/store/globalContent';
const config = useGlobalContentStore().config;

const selectedMarket = ref(config.currentMarket);
const marketSelectorOpen = ref(false);
const currentMarketName = ref('');
const suggestedMarketName = ref('');

const markets = computed(() => {
  return config.markets;
});

onMounted(() => {
  setCurrentMarketName();
});

const openMarketSelector = () => {
  marketSelectorOpen.value = !marketSelectorOpen.value;
};

const onMarketSelect = (market: IMarket) => {
  selectedMarket.value = market;
  marketSelectorOpen.value = false;
};

const emit = defineEmits<{
  (event: 'close-wrong-country-modal'): void,
}>();


const onMarketApply = () => {
  const ageGateCookie = useCookie('age_gate_market', {
    path: selectedMarket.value?.url || '/',
    maxAge: 60 * 60 * 24 * 180,
  });
  ageGateCookie.value = 'true';

  window.location.href = selectedMarket.value?.url || '/';
  emit('close-wrong-country-modal');
};

const getMarketImage = (countryCode: string) => {
  return '';
  return (
    require(`~/assets/icons/flag-defs.svg`) +
    `#flag-${countryCode.toLowerCase()}`
  );
};

const setCurrentMarketName = () => {
  if (selectedMarket.value) {
    currentMarketName.value = selectedMarket.value.country;
    setCurrentMarketFromIp();
  }
};

const setCurrentMarketFromIp = () => {
  const currentIp = config.ipCountry;

  const currentLocation = markets.value.find(
    (market) => market.countryCode === currentIp
  );

  if (currentLocation) {
    selectedMarket.value = currentLocation;
    suggestedMarketName.value = currentLocation.country;
  }
};

const closeModal = () => {
  const savedCountriesJSON =
    sessionStorage.getItem('ignored-wrong-country') || '[]';
  const savedCountries = savedCountriesJSON || '[]';
  const countries = JSON.parse(savedCountries);

  const newSavedCountries = countries.includes(
    config.currentMarket?.countryCode
  )
    ? countries
    : [...countries, config.currentMarket?.countryCode];

  const alteredCountries = JSON.stringify(newSavedCountries);
  sessionStorage.setItem('ignored-wrong-country', alteredCountries);
  emit('close-wrong-country-modal');
};
</script>
<style>
.market-appear-enter-active {
  transition: all 0.3s ease;
}
.market-appear-leave-active {
  transition: all 0.3s ease;
}

.market-appear-enter,
.market-appear-leave-to {
  max-height: 0;
}

.market-appear-enter-to,
.market-appear-leave {
  max-height: 460px;

  @screen md {
    max-height: 160px;
  }
}
</style>
